<template>

      <v-container>
        <h2>Переход на страницу ЛПУ...</h2>
        <v-card
                class="mt-6 pa-2"
                elevation="10"
                outlined
                :loading="loading"
        >

        </v-card>

      </v-container>
</template>

<script>

import {lpu} from "@/components/app/js/globals";



export default {
  name: 'Lpuid',
  data: () => ({
    name: 'Lpuid',
    loading: true
  }),
  //,
  //watch: {
  //  '$route.params' (to,from){
  //    console.log('----######-------------',to.lpuid)
  //  }
  //},
  async beforeMount() {
    /*
    let route_next='/'
    this.loading=true
    try {
      if (this.$route.params && this.$route.params.lpuid) {
          console.log('1',this.$route.params.lpuid)
        let index = lpu().findIndex(e => e.lpuid == this.$route.params.lpuid)
          console.log('2', index)
        if (index != -1) {
          let l=lpu()[index]
          console.log('3', l)
          await this.$store.dispatch('SET_LPU', l)
          console.log('4')
          //предустановки
          if(l.preset.length>0){
              console.log('5',l.preset)
              for( let j=0; j<l.preset.length; j++){
                  console.log('6')
                  console.log(l.preset[j])
                  await this.$store.dispatch(l.preset[j].set_store, l.preset[j].arr)
              }
          }
          console.log('7')
          route_next=l.routes[1]
        }
      }

      //toNext(this)
      await this.$router.push(route_next)


    }catch(err){
       console.log(this.data.name, err)
    }
    this.loading=false
    */
    this.loading=true
      //console.log('1111')
    if (this.$route.params && this.$route.params.lpuid) {
        localStorage.setItem('lpuid',this.$route.params.lpuid)

        await this.$store.dispatch('SET_GLOBAL', {lpuid:this.$route.params.lpuid})
        //console.log('this.$store.getters.GLOBAL ',this.$store.getters.GLOBAL)

        /*
        console.log('1111333333',this.$route.params.lpuid)
        let global=this.$store.getters.GLOBAL
        console.log('22222 ',global)
        global.lpuid=this.$route.params.lpuid
        await this.$store.dispatch('SET_GLOBAL', global)
        console.log(this.$store.getters.GLOBAL)

         */
    }
    this.loading=false
    await this.$router.push('/')

  }


  }
</script>
